export enum IdPart {
  Users = '/api/users/',
  Roles = '/api/roles/',
  Classes = '/api/roster/api/classes/',
  Subgroups = '/api/roster/api/student_subgroups/',
  ClassTeacher = '/api/roster/api/class_teachers/',
  Teachers = '/api/roster/api/teachers/',
  ManageUsers = '/api/user-management/api/users/',
  PlannerUserSelection = '/api/lesson-planner/api/user_selections/',
}

export enum StudentAssignmentStatusTransformed {
  Graded = 'graded',
  ToBeGraded = 'toBeGraded',
  Assigned = 'assigned',
  Started = 'started',
  Returned = 'returned',
}

export enum StudentAssignmentAllStatusTransformed {
  Graded = 'graded',
  ToBeGraded = 'toBeGraded',
  Assigned = 'assigned',
  Started = 'started',
  Returned = 'returned',
  Submitted = 'submitted',
  Archived = 'archived',
}

export enum UserPurpose {
  PREVIEW_ACCOUNT = 'preview_account',
}

export enum ProfileTypeEnum {
  STUDENT = 'Student',
  TEACHER = 'Teacher',
  CAMPUS_LEADER = 'CampusLeader',
  CAMPUS_CURRICULUM_LEADER = 'CampusCurriculumLeader',
  DISTRICT_CURRICULUM_LEADER = 'DistrictCurriculumLeader',
  PLATFORM_ADMIN = 'PlatformAdmin',
  ADMIN = 'SystemAdmin',
}

export enum ApplicationEnum {
  TEACHER_SUIT = 'TeacherSuit',
  STUDENT_SUIT = 'StudentSuit',
  MATHNATION_SUIT = 'MathnationSuit',
  ADMIN = 'Admin',
  PLATFORM_ADMIN = 'PlatformAdmin',
  CAMPUS_LEADER_SUIT = 'CampusLeaderSuit',
  CAMPUS_CURRICULUM_LEADER_SUIT = 'CampusCurriculumLeaderSuit',
  DISTRICT_CURRICULUM_LEADER_SUIT = 'DistrictCurriculumLeaderSuit',
  LOGIN = 'Login',
}

export enum DistrictPermission {
  TEACHER_SUIT = 'TeacherSuit',
  MATHNATION_SUIT = 'MathnationSuit',
  STUDENT_SUIT = 'StudentSuit',
  ADMIN = 'Admin',
  PLATFORM_ADMIN = 'PlatformAdmin',
}

export const enum Reports {
  RESPONSE_ANALYSIS = 'response-analysis-by-item',
  LEARNING_OUTCOMES = 'item-scores-by-tag-by-user',
}

export const enum ThemeEnum {
  DEFAULT = 'default',
  HIGH_CONTRAST = 'highContrast',
}
